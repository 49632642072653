import React, {
  Fragment,
  useContext,
  useEffect,
  useState,
  useReducer,
} from 'react';
import './overview.scss';
import RightArrow from './RightArrow';
import flow from '../../../assets/flow.json';
import allBadges from '../../shared/badges/allBadges';
import { Context } from '../../../context';
import { ReactComponent as Close } from '../../../assets/images/close.svg';
import { ReactComponent as SupportIcon } from '../../../assets/images/support.svg';
import { Redirect } from 'react-router-dom/cjs/react-router-dom.min';
import { Link } from 'react-router-dom/cjs/react-router-dom.min';
import useFetch from '../../../customHooks/useFetch';
import VideoPlayer from '../../shared/video-player/video-player';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import ExclamationPoint from './ExclamationPoint';
import ExclamationPoint2 from './ExclamationPoint2';

import setFirstVideo from '../../../assets/helpers/setFirstVideo';
import useStore from '../../../customHooks/useStore';

const BadgeDisplay = ({ badge, complete, progress }) => {
  const context = useContext(Context);

  const { language } = useStore((state) => {
    return {
      language: state.language,
    };
  });

  if (!progress) {
    return <></>;
  }

  const makeBadgesClickable = (badgeMod) => {
    if (6 - context.state.disabledArray.length > badge.module || complete) {
      return true;
    } else if (JSON.parse(progress.linkindex)[5] === 5) {
      return true;
    } else {
      return false;
    }
  };

  return makeBadgesClickable() ? (
    <Link
      to={badge.urlUsed}
      onClick={() => {
        context.setCurSectionIndex(badge.section);
        context.setCurSlide(badge.slideIndex);
        context.setCurModIndex(badge.module - 1);
      }}
      className={complete ? '' : 'missed'}
    >
      {!complete && (
        <div className='linkWrap'>
          <ExclamationPoint2 />
        </div>
      )}

      <img
        className={complete ? 'badgey' : 'greyed-out badgey'}
        src={
          language === 'es'
            ? process.env.PUBLIC_URL + `/badges/${badge.badgeName}sp.png`
            : process.env.PUBLIC_URL + `/badges/${badge.badgeName}.png`
        }
        alt='need to add alt'
      />
    </Link>
  ) : (
    <img
      className={complete ? 'badgey' : 'greyed-out badgey'}
      src={
        language === 'es'
          ? process.env.PUBLIC_URL + `/badges/${badge.badgeName}sp.png`
          : process.env.PUBLIC_URL + `/badges/${badge.badgeName}.png`
      }
      alt='need to add alt'
    />
  );
};

function Overview() {
  const context = useContext(Context);
  const [firstVisit, setFirstVisit] = useState(false);
  const [redirect, setRedirect] = useState(false);
  const [enableButton, setEnableButton] = useState(false);
  const [completedBadges, setCompletedBadges] = useState(false);
  const [progress, setProgress] = useState(false);
  const { language } = useStore((state) => {
    return {
      language: state.language,
    };
  });

  const handleBadgeGet = async () => {
    try {
      let data = await fetch(process.env.REACT_APP_API_URL + `/api/badge`, {
        method: 'GET',
        mode: 'cors',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${localStorage.getItem('token')}`,
        },
      });
      if (data.ok) {
        let response = await data.json();
        setCompletedBadges(response);
      } else {
        const errorMessage = await data.text();
        throw new Error(errorMessage);
      }
    } catch (err) {
      console.log(err);
    }
  };

  const getURL = (section) => {
    return `/course/overview/handout/${section}`;
  };

  useEffect(() => {
    let checkFirstVisit = localStorage.getItem('initialVideo') !== 'true';
    setFirstVisit(checkFirstVisit);
    handleBadgeGet();
    context.getProgressData(false).then((res) => {
      if (res) {
        setProgress(JSON.parse(res));
      }
    });
    context.setSidebarModuleOpen(0);
  }, []);

  const sortBadges = (type) => {
    const completedCheck = (item) => {
      if (completedBadges) {
        return completedBadges.includes(item.badgeName);
      } else {
        return false;
      }
    };
    return allBadges
      .filter((item) => item.type === type)
      .map((item) => (
        <BadgeDisplay
          badge={item}
          complete={completedCheck(item)}
          progress={progress}
        />
      ));
  };

  const badgeTypes = [
    { header: 'Module', items: sortBadges('module') },
    { header: 'Practice Assignment', items: sortBadges('practice') },
    { header: 'Activity', items: sortBadges('activity') },
  ];

  let modindex, linkindex, curLink, url;

  if (progress && localStorage.getItem('initialVideo') !== 'false') {
    modindex = progress.modindex;
    linkindex = JSON.parse(progress.linkindex);
    curLink = linkindex[modindex];
    url = flow[modindex + 1].links[curLink].url;
  }

  if (redirect) {
    return <Redirect to={'/course/module1/timeline/child-centered/intro'} />;
  }

  return (
    <section className='welcome' id={'fade'}>
      <div>
        <h1>
          Welcome {!firstVisit && 'Back,'} {localStorage.getItem('name')}!
        </h1>
        {localStorage.getItem('initialVideo') !== 'false' && (
          <div className='flex-center'>
            <Link
              className={'takeMeWhere'}
              to={url}
              onClick={() => {
                context.setSidebarModuleOpen(modindex);
                context.setCurSlide(0);
                context.setCurSectionIndex(curLink);
                context.setCurModLink(url);
              }}
            >
              <span>Take Me to Where I Left Off</span>
              <RightArrow />
            </Link>
          </div>
        )}
        {firstVisit && (
          <div className='video-parent'>
            <div className='video-wrap'>
              <VideoPlayer
                source={{
                  en: 749604203,
                  es: 1024896020,
                }}
                setVideoWatched={setEnableButton}
              />
            </div>
            <p className='init-vid-p'>
              {' '}
              Watch this video to unlock Module 1 and get started!
            </p>
          </div>
        )}
        {/* <div className="front-button-wrap">
          <button
            onClick={() => {
              if (firstVisit) {
                setFirstVideo();
                localStorage.setItem("initialVideo", "true");
                setRedirect(true);
              }
            }}
            className="continue-button"
          >
            {!firstVisit ? "Continue Where You Left Off" : "Let's Get Started!"}
          </button>
        </div> */}
        {firstVisit && (
          <div
            className={`front-button-wrap ${
              !enableButton ? 'disabled' : 'enabled'
            }`}
          >
            <button
              onClick={
                enableButton
                  ? () => {
                      if (firstVisit) {
                        localStorage.setItem('initialVideo', 'true');
                        context.setSidebarModuleOpen(0);
                        context.setCurSectionIndex(0);
                        setFirstVideo();
                        context.setDisabledArray();
                        setRedirect(true);
                      }
                    }
                  : null
              }
              className='continue-button'
            >
              Let's Get Started!
            </button>
          </div>
        )}
      </div>
      {!firstVisit && (
        <>
          <div className='middle-wrapper'>
            <section>
              <h2>Handouts</h2>
              <div className='badge-flex'>
                <Link
                  to={getURL(1)}
                  className={`${
                    6 - context.state.disabledArray.length >= 1 ? '' : 'disable'
                  } continue-button module-button`}
                >
                  Module 1
                </Link>
                <Link
                  to={getURL(2)}
                  className={`${
                    6 - context.state.disabledArray.length >= 2 ? '' : 'disable'
                  } continue-button module-button`}
                >
                  Module 2
                </Link>
                <Link
                  to={getURL(3)}
                  className={`${
                    6 - context.state.disabledArray.length >= 3 ? '' : 'disable'
                  } continue-button module-button`}
                >
                  Module 3
                </Link>
                <Link
                  to={getURL(4)}
                  className={`${
                    6 - context.state.disabledArray.length >= 4 ? '' : 'disable'
                  } continue-button module-button`}
                >
                  Module 4
                </Link>
                <Link
                  to={getURL(5)}
                  className={`${
                    6 - context.state.disabledArray.length >= 5 ? '' : 'disable'
                  } continue-button module-button`}
                >
                  Module 5
                </Link>
                <Link
                  to={getURL(6)}
                  className={`${
                    6 - context.state.disabledArray.length >= 6 ? '' : 'disable'
                  } continue-button module-button`}
                >
                  Module 6
                </Link>
              </div>
            </section>
            <section>
              <h2>Badges</h2>
              <div className='legendWrap'>
                <div className='legend'>
                  <img
                    src={
                      language === 'es'
                        ? process.env.PUBLIC_URL + '/badges/genericBadgeSp.png'
                        : process.env.PUBLIC_URL + '/badges/genericBadge.png'
                    }
                    alt='legend'
                  ></img>
                  <span>Completed</span>
                </div>
                <div className='legend'>
                  <img
                    className='greyed-out'
                    src={
                      language === 'es'
                        ? process.env.PUBLIC_URL + '/badges/genericBadgeSp.png'
                        : process.env.PUBLIC_URL + '/badges/genericBadge.png'
                    }
                    alt='legend'
                  ></img>
                  <span>Not Completed</span>
                </div>
                <div className='legend'>
                  <div className={'missed legendMissed'}>
                    <div className='linkWrap'>
                      <ExclamationPoint2 />
                    </div>
                    <img
                      src={
                        language === 'es'
                          ? process.env.PUBLIC_URL +
                            '/badges/genericBadgeSp.png'
                          : process.env.PUBLIC_URL + '/badges/genericBadge.png'
                      }
                      alt='need to add alt'
                    />
                  </div>
                  <span className='longText'>
                    You missed this one! Click it to complete the badge!
                  </span>
                </div>
              </div>
              {badgeTypes.map((type) => {
                return (
                  <div className='sub-mod'>
                    <h3>{type.header + ' Badges'}</h3>
                    <div className='badge-flex'>{type.items}</div>
                  </div>
                );
              })}
            </section>
          </div>

          <div className='supportWrapper'>
            <a
              href={
                language === 'es'
                  ? 'https://osu.az1.qualtrics.com/jfe/form/SV_1UoOfkJOtJFvzL0'
                  : 'https://osu.az1.qualtrics.com/jfe/form/SV_1UoOfkJOtJFvzL0'
              }
              target={'_blank'}
              className='supportButton'
              rel='noreferrer'
            >
              <SupportIcon />
              <p>Need Tech Support?</p>
            </a>
          </div>
        </>
      )}
    </section>
  );
}

export default Overview;
